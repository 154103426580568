import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWebsiteID } from "../../common-functions";
import { useCookies } from "react-cookie";

export const useMarketingPopup = () => {
    const [homePop, setHomePop] = useState([]);
    const [homePopMobile, setHomePopMobile] = useState([]);
    const [showMarketingPopEmail, setShowMarketingPopEmail] = useState(false);
    const [websiteID, setWebsiteID] = useState('');
    const [showMarketingPop, setShowMarketingPop] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [error, setError] = useState("");
    const [state, setState] = useState({
        open: true,
        vertical: 'top',
        horizontal: 'center',
    });

    const [cookie, setCookie] = useCookies(["store"])
    const configData = useSelector((state) => state.configdata);

    const { vertical, horizontal, open } = state;

    const handleSnackClose = () => {
        setState({ ...state, open: false });
    };

    useEffect(() => {
        const bannerData = (configData && configData.homepage_banner_popup_popup1) ? configData.homepage_banner_popup_popup1 : ''
        setHomePop((bannerData) ? JSON.parse(bannerData) : [])
        const bannerDataMobile = (configData && configData.homepage_banner_popup_mobilepopup1) ? configData.homepage_banner_popup_mobilepopup1 : ''
        setHomePopMobile((bannerDataMobile) ? JSON.parse(bannerDataMobile) : [])
    }, [configData])

    useEffect(() => {

        const website_id = getWebsiteID(cookie)
        var emailName = "home_marketing_email" + website_id
        if (cookie && cookie[emailName]) {
            setShowMarketingPopEmail(false)
        } else {
            setShowMarketingPopEmail(true)
        }
        setWebsiteID(website_id)

        if (process.browser) {
            window.addEventListener("load", function () {
                if (website_id == 1) {

                    if (process.env.NEXT_PUBLIC_DEFAULT_POPUP1 == "0" || (cookie?.home_marketing1)) {
                        setShowMarketingPop(false)
                    } else {
                        setTimeout(() => {
                            setShowMarketingPop(true)
                        }, 100);
                    }
                } else {
                    if (process.env.NEXT_PUBLIC_DEFAULT_POPUP2 == "0" || (cookie?.home_marketing2)) {
                        setShowMarketingPop(false)
                    } else {
                        setTimeout(() => {
                            setShowMarketingPop(true)
                        }, 100);
                    }
                }
            });
        }
    }, [cookie]);

    return {
        showLoader,
        error,
        vertical,
        horizontal,
        homePop,
        showMarketingPop,
        showMarketingPopEmail,
        cookie,
        state,
        homePopMobile,
        websiteID,
        setShowLoader,
        setCookie,
        setError,
        setState,
        setShowMarketingPop,
        handleSnackClose,
    }
}


import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import dynamic from 'next/dynamic';
import { useMarketingPopup } from '/lib/hooks/MarketingPopup/useMarketingPopup';
import FormLoader from '../ui/FormLoader';

const OpenMarketingPopup = dynamic(() => import('./openMarketingPopup'));

function MarketingPopup() {

    const {
        showLoader,
        error,
        vertical,
        horizontal,
        homePop,
        showMarketingPop,
        showMarketingPopEmail,
        cookie,
        state,
        homePopMobile,
        websiteID,
        setShowLoader,
        setCookie,
        setError,
        setState,
        setShowMarketingPop,
        handleSnackClose,
    } = useMarketingPopup();

    return (
        <>
            <FormLoader show={showLoader} />
            {error && (
                <Snackbar
                    autoHideDuration={1500}
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleSnackClose}
                    message={error}
                    key={vertical + horizontal}
                />
            )}
            {
                (homePop?.banner && showMarketingPop) ? (
                    <OpenMarketingPopup
                        showMarketingPopEmail={showMarketingPopEmail}
                        showMarketingPop={showMarketingPop}
                        cookie={cookie}
                        state={state}
                        homePop={homePop}
                        homePopMobile={homePopMobile}
                        setShowLoader={setShowLoader}
                        setCookie={setCookie}
                        setError={setError}
                        setState={setState}
                        websiteID={websiteID}
                        setShowMarketingPop={setShowMarketingPop}
                    />
                ) : null
            }
        </>
    )
}


export default MarketingPopup;